:root {
  --table-height: calc(100vh - 250px);
}

.grid-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.MuiTableContainer-root {
  max-height: none !important;
  height: var(--table-height) !important;
}

.dx-g-bs4-virtual-table,
.dx-g-bs4-virtual-table-container,
.dx-virtual-table-container {
  height: auto !important;
  min-height: calc(var(--table-height) - 100px) !important;
}

.dx-pager {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
}

@media (max-width: 1600px) {
  :root {
    --table-height: calc(100vh - 220px);
  }
}

@media (max-width: 1200px) {
  :root {
    --table-height: calc(100vh - 200px);
  }
}

@media (max-width: 992px) {
  :root {
    --table-height: calc(100vh - 180px);
  }
}